import { filter, get, map, toInteger, toString } from "lodash/fp";
import { FieldMap } from "../types/field-map";
import { ICustomer, IEstimate, IVehicle, IVehicleBrand } from "../types";
import { parseData as parseVehicle } from "../transformers/vehicle";
import {
  parseData as parseSchedule,
  parsePayload as parseSchedulePayload,
} from "./schedule";
import {
  parseData as parseService,
  parsePayload as parseServicePayload,
} from "./service-requirement";
import dayjs from "dayjs";
import { STATUS_TEXT_MAPPING } from "../constants/estimate-status";

const FIELD_MAPPING: FieldMap[] = [
  {
    key: "id",
    field: "id",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "customerName",
    field: "customers",
    render: (customer: ICustomer) => "",
    exclude: true,
    hidden: true,
  },
  {
    key: "plateNumber",
    field: "vehicle.plate_no",
    exclude: true,
  },
  {
    key: "vehicleDetails",
    field: "vehicle",
    transform: { response: (value: any) => parseVehicle(value) },
    render: (vehicle: IVehicle) =>
      `${vehicle.vehicleYear} ${(vehicle.vehicleBrand as IVehicleBrand).name} ${vehicle.model
      }`,
    exclude: true,
  },
  {
    key: "overview",
    field: "vehicle",
    transform: { response: (value: any) => parseVehicle(value) },
    render: (vehicle: IVehicle) => `${vehicle.mileage} Mileage`,
    exclude: true,
  },
  {
    key: "status",
    field: { response: "latest_status", payload: "status" },
    transform: {
      response: (value: string) =>
        STATUS_TEXT_MAPPING[toString(value)] ?? toString(value),
      payload: (value: string) =>
        Object.keys(STATUS_TEXT_MAPPING).find(
          (key) => STATUS_TEXT_MAPPING[key] == toString(value)
        ) ?? toString(value),
    },
    render: (value: any) => toString(value),
  },
  {
    key: "dateCreated",
    field: "created_at",
    render: (value: any) => dayjs(value).format("DD MMM YYYY"),
    exclude: true,
  },
  {
    key: "description",
    field: "description",
    transform: { response: (value: any) => toString(value) },
    hidden: true,
  },
  {
    key: "hasTaxExemptions",
    field: "has_tax_exemptions",
    transform: { response: (value: any) => toString(value) },
    hidden: true,
  },
  {
    key: "taxExemptPercentage",
    field: "tax_exempt_percentage",
    transform: { response: (value: any) => toString(value) },
    hidden: true,
  },
  {
    key: "taxTotalAmount",
    field: "tax_total_amount",
    transform: { response: (value: any) => toString(value) },
    hidden: true,
  },
  {
    key: "customerId",
    field: "customer_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "vehicleId",
    field: "vehicle_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "shopVehicleId",
    field: "shop_vehicle_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },

  // Service location and address
  {
    key: "serviceLocationTypeId",
    field: "service_location_type_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "shopId",
    field: "shop_id",
    transform: {
      response: (value: any) => (!!value ? toInteger(value) : null),
    },
    hidden: true,
  },
  {
    key: "zipcode",
    field: "zipcode",
    transform: { response: (value: any) => toString(value) },
    hidden: true,
  },
  {
    key: "address",
    field: "address",
    transform: { response: (value: any) => toString(value) },
    hidden: true,
  },
  {
    key: "country",
    field: "country",
    transform: { response: (value: any) => toString(value) },
    hidden: true,
  },
  {
    key: "province",
    field: "province",
    transform: { response: (value: any) => toString(value) },
    hidden: true,
  },
  {
    key: "city",
    field: "city",
    transform: { response: (value: any) => toString(value) },
    hidden: true,
  },
  {
    key: "barangay",
    field: "barangay",
    transform: { response: (value: any) => toString(value) },
    hidden: true,
  },

  {
    key: "serviceRequirements",
    field: "service_requirements",
    transform: {
      response: (value: any[]) => map(parseService, value),
      payload: (value: any[]) => map(parseServicePayload, value),
    },
    hidden: true,
  },
  {
    key: "users",
    field: "users",
    transform: {
      payload: (value: any[]) =>
        map(
          ({ technicianId }) => technicianId,
          filter(({ technicianId }) => !!technicianId, value)
        ),
    },
    hidden: true,
  },

  // Amount
  {
    key: "discountTotalAmount",
    field: "discount_total_amount",
  },
  {
    key: "laborTotalAmount",
    field: "labor_total_amount",
  },
  {
    key: "partsTotalAmount",
    field: "parts_total_amount",
  },
  {
    key: "toolTotalAmount",
    field: "tool_total_amount",
  },
  {
    key: "grandTotal",
    field: "grand_total",
  },

  // Schedule
  {
    key: "schedules",
    field: "schedules",
    transform: {
      response: (value: any[]) => map(parseSchedule, value),
      payload: (value: any[]) => map(parseSchedulePayload, value),
    },
    hidden: true,
  },
  {
    key: "scheduleId",
    field: "schedule_id",
    transform: {
      response: (value: any) => (!!value ? toInteger(value) : null),
    },
    hidden: true,
  },
  {
    key: "startDate",
    field: "start_date",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "endDate",
    field: "end_date",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "startTime",
    field: "start_time",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "endTime",
    field: "end_time",
    transform: { response: (value: any) => toString(value) },
  },
];

export const parseData = (json: any): IEstimate => {
  return FIELD_MAPPING.reduce((acc: IEstimate, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IEstimate);
};

export const parsePayload = (json: any) => {
  return FIELD_MAPPING.reduce((acc, curr: FieldMap) => {
    const { key, field, transform, exclude } = curr;
    const value = get(key, json);
    if (exclude) return acc;
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {});
};

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({
    column: field.key,
    path: field.path || field.key,
    render: field.render,
  })
);
