import { toInteger, toString, get } from "lodash/fp";
import { FieldMap } from "../types/field-map";
import { IStandardService } from "../types";
import { map } from "lodash/fp";
import { parseData as parseLaborData } from "./labor"
import { parseData as parsePartData } from "./part"
import { parseData as parseToolData } from "./tool"
import { parseData as parseDiscountData } from "./discount"

const FIELD_MAPPING: FieldMap[] = [
  {
    key: "id",
    field: "id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "name",
    field: "name",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "description",
    field: "description",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "labors",
    field: "labors",
    transform: { response: (value: any[]) => map(parseLaborData, value)},
    hidden: true,
  },
  {
    key: "parts",
    field: "parts",
    transform: { response: (value: any[]) => map(parsePartData, value)},
    hidden: true,
  },
  {
    key: "tools",
    field: "tools",
    transform: { response: (value: any[]) => map(parseToolData, value)},
    hidden: true,
  },
  {
    key: "discounts",
    field: "discounts",
    transform: { response: (value: any[]) => map(parseDiscountData, value)},
    hidden: true,
  },
];

export const parseData = (json: any): IStandardService => {
  return FIELD_MAPPING.reduce((acc: IStandardService, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IStandardService);
};

export const parsePayload = (json: any) => {
  return FIELD_MAPPING.reduce((acc, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {});
}

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({ column: field.key, path: field.path || field.key })
);
