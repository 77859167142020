import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { isAxiosError } from "../../utils/axios";
import { IError } from "../../types/error";
import { ISearchPayload, IShop } from "../../types";
import { map } from "lodash/fp";
import { parseData } from "../../transformers/shop";
import { ResponseStatus } from "../../constants";

export const fetch = createAsyncThunk<
  { data: IShop[], total: number },
  ISearchPayload | undefined,
  { rejectValue: IError }
>("shop/list/fetch", async (params, { rejectWithValue }) => {
  try {
    const { data } = await request.get("api/shops", { params });
    return {
      data: map(parseData, data.data),
      total: data.total ?? 0,
    };
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error;
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const remove = createAsyncThunk<
  IShop,
  number,
  { rejectValue: IError }
>("shop/list/remove", async (id, { rejectWithValue }) => {
  try {
    const { data } = await request.delete(`api/shops/${id}`);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error;
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

type InitialState = {
  data: IShop[];
  total: number;
  status: "loading" | "idle" | "deleted";
  error: string | null;
};

const initialState: InitialState = {
  data: [],
  total: 0,
  status: "idle",
  error: null,
};

export const slice = createSlice({
  name: "shop/list",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetch.fulfilled, (state, { payload }) => {
      state.data = payload.data;
      state.total = payload.total;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetch.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(remove.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.status = "deleted";
      state.error = null;
    });
    builder.addCase(remove.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;
