import { map } from "lodash/fp";
import {
  IDiscount,
  IFuelType,
  IJobType,
  ILabor,
  ILaborType,
  IPart,
  IPartCategory,
  IPartSubcategory,
  ISearchPayload,
  IServiceLocationType,
  IShop,
  IStandardService,
  ITool,
  IToolType,
  IVehicle,
  IVehicleBrand,
  IVehicleType,
} from "../types";
import { IError } from "../types/error";
import request, { isAxiosError } from "../utils/axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { parseData as parseStandardService } from "../transformers/standard-service";
import { parseData as parseJobType } from "../transformers/job-type";
import { parseData as parseLabor } from "../transformers/labor";
import { parseData as parseLaborType } from "../transformers/labor-type";
import { parseData as parseTool } from "../transformers/tool";
import { parseData as parseToolType } from "../transformers/tool-type";
import { parseData as parseDiscount } from "../transformers/discount";
import { parseData as parsePart } from "../transformers/part";
import { parseData as parsePartCategory } from "../transformers/part-category";
import { parseData as parsePartSubcategory } from "../transformers/part-subcategory";
import { parseData as parseVehicle } from "../transformers/vehicle";
import { parseData as parseVehicleType } from "../transformers/vehicle-type";
import { parseData as parseVehicleBrand } from "../transformers/vehicle-brand";
import { parseData as parseFuelType } from "../transformers/fuel-type";
import { parseData as parseShop } from "../transformers/shop";
import { parseData as parseServiceLocationType } from "../transformers/service-location-type";
import { ResponseStatus } from "../constants";
import { ICustomer, parseData as parseCustomer } from "./customers/slice";
import { IUser, parseData as parseUser } from "./usersSlice";

export const getCountries = createAsyncThunk(
  "option/getCountries",
  async (args: undefined, { rejectWithValue }) => {
    try {
      const { data } = await request.get("/api/countries", args);
      return data.data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error as { response: { data: any; status: any } };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const getProvinces = createAsyncThunk(
  "option/getProvinces",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await request.get("/api/provinces", { params });
      return data.data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error as { response: { data: any; status: any } };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const getCities = createAsyncThunk(
  "option/getCities",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await request.get("/api/cities", { params });
      return data.data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error as { response: { data: any; status: any } };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const getBarangays = createAsyncThunk(
  "option/getBarangays",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await request.get("/api/barangays", { params });
      return data.data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error as { response: { data: any; status: any } };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const getStandardServices = createAsyncThunk<
  IStandardService[],
  undefined,
  { rejectValue: IError }
>("option/getStandardServices", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/standard-services");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseStandardService, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getJobTypes = createAsyncThunk<
  IJobType[],
  undefined,
  { rejectValue: IError }
>("option/getJobTypes", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/job-types");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseJobType, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getLabors = createAsyncThunk<
  ILabor[],
  undefined,
  { rejectValue: IError }
>("option/getLabors", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/labors");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseLabor, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getLaborTypes = createAsyncThunk<
  ILaborType[],
  undefined,
  { rejectValue: IError }
>("option/getLaborTypes", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/labor-types");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseLaborType, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getToolTypes = createAsyncThunk<
  IToolType[],
  undefined,
  { rejectValue: IError }
>("option/getToolTypes", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/tool-types");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseToolType, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getParts = createAsyncThunk<
  IPart[],
  undefined,
  { rejectValue: IError }
>("option/getParts", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/parts");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parsePart, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getPartCategories = createAsyncThunk<
  IPartCategory[],
  undefined,
  { rejectValue: IError }
>("option/getPartCategories", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/part-categories");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parsePartCategory, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getPartSubcategories = createAsyncThunk<
  IPartSubcategory[],
  undefined,
  { rejectValue: IError }
>("option/getPartSubcategories", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/part-subcategories");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parsePartSubcategory, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getTools = createAsyncThunk<
  ITool[],
  undefined,
  { rejectValue: IError }
>("option/getTools", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/tools");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseTool, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getDiscounts = createAsyncThunk<
  IDiscount[],
  undefined,
  { rejectValue: IError }
>("option/getDiscounts", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/discounts");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseDiscount, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getVehicleTypes = createAsyncThunk<
  IVehicleType[],
  undefined,
  { rejectValue: IError }
>("option/getVehicleTypes", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/vehicle-types");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseVehicleType, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getVehicleBrands = createAsyncThunk<
  IVehicleType[],
  undefined,
  { rejectValue: IError }
>("option/getVehicleBrands", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/vehicle-brands");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseVehicleBrand, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getFuelTypes = createAsyncThunk<
  IVehicleType[],
  undefined,
  { rejectValue: IError }
>("option/getFuelTypes", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/fuel-types");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseFuelType, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getShops = createAsyncThunk<
  IShop[],
  undefined,
  { rejectValue: IError }
>("option/getShops", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/shops");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseShop, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getShopVehicles = createAsyncThunk<
  IShop,
  number,
  { rejectValue: IError }
>("option/getShopVehicles", async (id, { rejectWithValue }) => {
  try {
    const { data } = await request.get(`/api/shops/${id}`);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseShop(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getCustomers = createAsyncThunk<
  ICustomer[],
  undefined,
  { rejectValue: IError }
>("option/getCustomers", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/customers");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseCustomer, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getCustomerVehicles = createAsyncThunk<
  IVehicle[],
  Partial<ISearchPayload> & { customer_id: number },
  { rejectValue: IError }
>("option/getCustomerVehicles", async (params, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/customer-vehicles", { params });

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(
      parseVehicle,
      map(({ vehicle }) => vehicle, data.data)
    );
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const getAdvisorUsers = createAsyncThunk<
  IUser[],
  ISearchPayload | undefined,
  { rejectValue: IError }
>(
  "option/getAdvisorUsers",
  async (params: ISearchPayload | undefined, { rejectWithValue }) => {
    try {
      const { data } = await request.get("api/users", {
        params: { ...params, role_id: 4 },
      });
      return map(parseUser, data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error as {
          response: { data: any; status: number };
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const getTechnicianUsers = createAsyncThunk<
  IUser[],
  ISearchPayload | undefined,
  { rejectValue: IError }
>(
  "option/getTechnicianUsers",
  async (params: ISearchPayload | undefined, { rejectWithValue }) => {
    try {
      const { data } = await request.get("api/users", {
        params: { ...params, role_id: 5 },
      });
      return map(parseUser, data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error as {
          response: { data: any; status: number };
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const getServiceLocationTypes = createAsyncThunk<
  IServiceLocationType[],
  undefined,
  { rejectValue: IError }
>("option/getServiceLocationTypes", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.get("/api/service-location-types");

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return map(parseServiceLocationType, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

type optionState = {
  countries: any[];
  provinces: any[];
  cities: any[];
  barangays: any[];
  standardServices: IStandardService[];
  jobTypes: IJobType[];
  labors: ILabor[];
  laborTypes: ILaborType[];
  tools: ITool[];
  toolTypes: IToolType[];
  parts: IPart[];
  partCategories: IPartCategory[];
  partSubcategories: IPartSubcategory[];
  discounts: IDiscount[];
  vehicleTypes: IVehicleType[];
  vehicleBrands: IVehicleBrand[];
  fuelTypes: IFuelType[];
  shops: IShop[];
  shopVehicles: IVehicle[];
  customers: ICustomer[];
  customerVehicles: IVehicle[];
  advisorUsers: IUser[];
  technicianUsers: IUser[];
  serviceLocationTypes: IServiceLocationType[],
  status: "loading" | "idle";
  error: string | null;
};

const initialState: optionState = {
  countries: [],
  provinces: [],
  cities: [],
  barangays: [],
  standardServices: [],
  jobTypes: [],
  labors: [],
  laborTypes: [],
  tools: [],
  toolTypes: [],
  parts: [],
  partCategories: [],
  partSubcategories: [],
  discounts: [],
  vehicleTypes: [],
  vehicleBrands: [],
  fuelTypes: [],
  shops: [],
  shopVehicles: [],
  customers: [],
  customerVehicles: [],
  advisorUsers: [],
  technicianUsers: [],
  serviceLocationTypes: [],
  status: "idle",
  error: null,
};

export const optionSlice = createSlice({
  name: "option",
  initialState: { ...initialState },
  reducers: {
    reset: (state, action: PayloadAction<any>) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, { payload }) => {
      state.countries = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getProvinces.fulfilled, (state, { payload }) => {
      state.provinces = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getCities.fulfilled, (state, { payload }) => {
      state.cities = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getBarangays.fulfilled, (state, { payload }) => {
      state.barangays = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getStandardServices.fulfilled, (state, { payload }) => {
      state.standardServices = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getJobTypes.fulfilled, (state, { payload }) => {
      state.jobTypes = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getLabors.fulfilled, (state, { payload }) => {
      state.labors = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getLaborTypes.fulfilled, (state, { payload }) => {
      state.laborTypes = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getToolTypes.fulfilled, (state, { payload }) => {
      state.toolTypes = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getParts.fulfilled, (state, { payload }) => {
      state.parts = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getPartCategories.fulfilled, (state, { payload }) => {
      state.partCategories = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getPartSubcategories.fulfilled, (state, { payload }) => {
      state.partSubcategories = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getTools.fulfilled, (state, { payload }) => {
      state.tools = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getDiscounts.fulfilled, (state, { payload }) => {
      state.discounts = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getVehicleTypes.fulfilled, (state, { payload }) => {
      state.vehicleTypes = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getVehicleBrands.fulfilled, (state, { payload }) => {
      state.vehicleBrands = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getFuelTypes.fulfilled, (state, { payload }) => {
      state.fuelTypes = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getShops.fulfilled, (state, { payload }) => {
      state.shops = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(getShopVehicles.fulfilled, (state, { payload }) => {
      const { shopVehicles } = payload
      state.shopVehicles = shopVehicles;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getCustomers.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getCustomers.fulfilled, (state, { payload }) => {
      state.customers = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(getCustomers.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(getCustomerVehicles.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getCustomerVehicles.fulfilled, (state, { payload }) => {
      state.customerVehicles = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(getCustomerVehicles.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(getAdvisorUsers.fulfilled, (state, { payload }) => {
      state.advisorUsers = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getTechnicianUsers.fulfilled, (state, { payload }) => {
      state.technicianUsers = payload;
      state.status = "idle";
      state.error = null;
    });

    builder.addCase(getServiceLocationTypes.fulfilled, (state, { payload }) => {
      state.serviceLocationTypes = payload;
      state.status = "idle";
      state.error = null;
    });
  },
});

export const { reset } = optionSlice.actions;

export default optionSlice.reducer;
