import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Drawer, DrawerHeader } from "./Drawer";
import ProfileMenu from "./ProfileMenu";
import {
  Box,
  List,
  ListItem,
  ListItemButton as MuiListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemButtonProps,
  Collapse,
} from "@mui/material";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MENU_MAPPING from "../menu";
import Each from "./Each";
import { useAuth } from "./AuthProvider";
import Logo from "./Logo";

const ListItemButton = styled(MuiListItemButton)<
  ListItemButtonProps | GatsbyLinkProps<any>
>(({ theme }) => ({
  "&.Mui-selected": {
    background:
      "linear-gradient(91deg, #DFDFDF 48.83%, rgba(245, 245, 245, 0.00) 167.19%)",
  },
}));

interface ListItemCollapseProps extends ListItemButtonProps {
  open?: boolean;
  menu: any;
  layer: number;
}

const ListItemCollapse = (props: ListItemCollapseProps) => {
  const [collapse, setCollapse] = useState(false);
  const handleClick = () => {
    setCollapse(!collapse);
  };

  return (
    <React.Fragment>
      <ListItemButton
        onClick={handleClick}
        sx={{
          minHeight: 48,
          justifyContent: props.open ? "initial" : "center",
          px: 2.5,
          ...(props.layer && { pl: props.layer * 4 }),
        }}
      >
        {props.menu.Icon && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: props.open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <props.menu.Icon />
          </ListItemIcon>
        )}
        <ListItemText
          primary={props.menu.label}
          sx={{ opacity: props.open ? 1 : 0 }}
          primaryTypographyProps={{
            fontSize: (theme) => theme.typography.body2.fontSize,
          }}
        />
        {props.open && (collapse ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={collapse} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </React.Fragment>
  );
};

interface SidebarProps {
  open?: boolean;
  width: number;
}

const Sidebar: React.FC<SidebarProps> = ({ open, width }) => {
  const { menu } = useAuth();
  const renderListItem = (items: typeof menu, layer: number) => {
    return (
      <Each
        of={items}
        render={(item) => {
          const menu = { ...item, ...MENU_MAPPING[item.alias] };
          return menu.children?.length ? (
            <ListItemCollapse open={open} menu={menu} layer={layer}>
              {renderListItem(menu.children, layer + 1)}
            </ListItemCollapse>
          ) : (
            <ListItem disablePadding>
              <ListItemButton
                {...(menu.pathname && {
                  component: GatsbyLink,
                  to: menu.pathname,
                })}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  ...(layer && { pl: layer * 4 }),
                }}
              >
                {menu.Icon && (
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <menu.Icon />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={menu.label}
                  primaryTypographyProps={{
                    fontSize: (theme) => theme.typography.body2.fontSize,
                  }}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          );
        }}
      />
    );
  };
  return (
    <Drawer width={width} variant="permanent" open={open}>
      <DrawerHeader sx={{ justifyContent: "center" }}>
        <Logo sx={{ width: "173px", height: "26px" }} />
      </DrawerHeader>
      <ProfileMenu compact={!open} />
      <List>
        <ListItem>
          <ListItemText secondary="Menu" />
        </ListItem>
        {renderListItem(menu || [], 0)}
      </List>
    </Drawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.number.isRequired,
};

export default Sidebar;
