import { toInteger, toString, get, map } from "lodash/fp";
import { FieldMap } from "../types/field-map";
import { IServiceRequirement, IStandardService } from "../types";

const FIELD_MAPPING: FieldMap[] = [
  {
    key: "id",
    field: "id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "standardServiceId",
    field: "standard_service_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "standardService",
    field: "standard_service",
    transform: { response: (value: IStandardService) => value.name },
    exclude: true,
  },
  {
    key: "jobTypeId",
    field: "job_type_id",
    transform: { response: (value: number | null) => value ? toInteger(value) : null },
    hidden: true,
  },
  {
    key: "description",
    field: "description",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "notes",
    field: "notes",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "displayCounter",
    field: "display_counter",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "discounts",
    field: "discounts",
    transform: {
      payload: (value: any[]) =>
        map(
          ({ id, totalAmount, remarks }) => ({
            discount_id: id,
            total_amount: totalAmount,
            remarks: remarks ?? "",
          }),
          value
        ),
    },
  },
  {
    key: "labors",
    field: "labors",
    transform: {
      payload: (value: any[]) =>
        map(
          ({ id, totalAmount, remarks }) => ({
            labor_id: id,
            total_amount: totalAmount,
            remarks: remarks ?? "",
          }),
          value
        ),
    },
  },
  {
    key: "parts",
    field: "parts",
    transform: {
      payload: (value: any[]) =>
        map(
          ({ id, totalAmount, remarks }) => ({
            part_id: id,
            total_amount: totalAmount,
            remarks: remarks ?? "",
          }),
          value
        ),
    },
  },
  {
    key: "tools",
    field: "tools",
    transform: {
      payload: (value: any[]) =>
        map(
          ({ id, totalAmount, remarks }) => ({
            tool_id: id,
            total_amount: totalAmount,
            remarks: remarks ?? "",
          }),
          value
        ),
    },
  },
];

export const parseData = (json: any): IServiceRequirement => {
  return FIELD_MAPPING.reduce((acc: IServiceRequirement, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IServiceRequirement);
};

export const parsePayload = (json: any) => {
  return FIELD_MAPPING.reduce((acc, curr: FieldMap) => {
    const { key, field, transform, exclude } = curr;
    const value = get(key, json);
    if (exclude) return acc;
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {});
};

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({ column: field.key, path: field.path || field.key })
);
